<template>
  <div class="master-login-container">
    <img src="@/assets/logo.png" alt="">
    <div class="login-form">
      <form>
        <h1>관리자 로그인</h1>
        <div class="label-box">
          <label>
            아이디
          </label>
          <input type="text" placeholder="아이디 입력" class="custom-input-box" v-model="id">
        </div>
        <div class="label-box">
          <label>
            패스워드
          </label>
          <input type="password" placeholder="패스워드 입력" class="custom-input-box" v-model="password" v-on:keypress.enter.prevent="getLogin" autoComplete="off">
        </div>
      </form>
      <button class="custom-btn-box" @click="getLogin">로그인</button>
    </div>
    <p class="footer-title">COPYRIGHT(C) 2021 ASSETTA. ALL RIGHT RESERVED.</p>
    <Progress :isProgress="isProgress"/>
  </div>
</template>

<script>

import Progress from "@/components/Progress";
import axios from "@/axios";

export default {
  name: "Login",
  components: {Progress},
  data() {
    return {
    //   id: 'admin',
    //   password: 'Admin123!',
      id: '',
      password: '',
      isProgress: false,
    }
  },
  methods: {
    getLogin() {
      const self = this;

      const data = JSON.stringify({
        "id": self.id,
        "password": self.password
      });

      const config = {
        method: 'post',
        url: '/auth/admin',
	headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
          .then(res => {
            localStorage.token = JSON.stringify(res.data);
            self.$router.push('/home');
          })
          .catch(()=>{
            self.$swal.fire({title: '로그인 실패', text: '계정 정보를 확인하세요.', icon: 'error'})
          })

    },
  }

}
</script>

<style scoped>

.master-login-container {
  font-weight: 500;
  background: #e6f2ff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 148px;
}

.master-login-container img {
  margin-bottom: 40px;
}

.master-login-container .login-form {
  margin: 0 auto;
  width: 418px;
  height: 376px;
  background: #ffffff;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 40px 20px;
}

.master-login-container .login-form h1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

.master-login-container .label-box label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272833;
  text-align: left !important;
}

.master-login-container .label-box {
  text-align: left;
  margin-top: 20px;
}

.master-login-container .label-box input {
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  margin-bottom: 0;
}

.master-login-container .custom-btn-box {
  margin: 40px 0;
  background: #4768ee;
}

.master-login-container .footer-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #757ca1;
  margin-top: 40px;
}

.custom-input-box {
  padding: 12px 8px;
  height: 40px;
  background: #FFFFFF;
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
}

.custom-input-box::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
}


.custom-btn-box {
  width: 100%;
  height: 40px;
  background: #4C9AF5;
  border: 0;
  font-family: 'NotoSansKR', serif;
  font-weight: 700;
  border-radius: 4px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}

</style>
